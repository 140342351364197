.booking-modal {
  & .nav-tabs .nav-link {
    border: none;
    color: #6c757d;
    font-weight: bold;
    &.active {
      border-bottom: 1px solid $primary;
    }
  }

  & .fa-times {
    color: $primary;
    font-size: 20px;
    margin-left: auto;
    cursor: pointer;
  }
}

.request-update-modal .fa-times {
  color: $primary;
  font-size: 20px;
  margin-left: auto;
  cursor: pointer;
}
