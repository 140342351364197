.jobs-table .DatePicker__calendarContainer {
    position: relative;
}

.jobs-table .DatePicker__calendarContainer .Calendar {
    position: absolute;
    z-index: 200000 !important;
    left: -100%;
}

.jobs-table .DatePicker,
input.DatePicker__input.-ltr {
  position: inherit;
}

.job-table-container .table-responsive {
    overflow: visible !important;
} 


/*=========================
    # OWNER DETAILS #
=========================*/

.owner-details-form .DatePicker__calendarContainer {
    position: relative;
}

.owner-details-form .DatePicker__calendarContainer .Calendar {
    position: absolute;
    z-index: 200000 !important;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100%;
    max-width: 360.25px;
}

.owner-details-form .DatePicker,
.owner-details-form input.DatePicker__input.-ltr {
  position: inherit;
}

