$btn-color: #495057;
$btn-bg-color: #fff;

.form-btn-white,
.form-btn-white:hover,
.custom-form-select {
  color: $btn-color !important;
  border: 1px solid $btn-bg-color !important;
  background-color: $btn-bg-color !important;
  border-radius: 0.25rem !important;
}

.DatePicker,
input.DatePicker__input.-ltr {
  width: 100%;
}

.DatePicker__input {
  text-align: left;
}

.form-control:disabled,
.form-control[readonly],
input.DatePicker__input:disabled,
.form-select:disabled {
  background-color: #eeeeee;
}

input.DatePicker__input.-ltr {
  padding: 0.45rem 0.9rem;
  font-size: 0.875rem;
  font-weight: 400;
  border: 1px solid var(--ct-input-border-color);
  appearance: none;
  border-radius: 0.2rem;
}

.Calendar__day.-selected,
.Calendar__day.-selectedEnd,
.Calendar__day.-selectedStart {
  background: $primary;
}

.react-select__single-value {
  color: $btn-color !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.pink-btn {
  margin: 0px 5px;
}

@media screen and (max-width: 1670px) {
  .reverse-on-xl {
    flex-direction: column-reverse !important;
    align-items: flex-start !important;
  }
}

@media screen and (max-width: 1490px) {
  .confirm-bookings-select {
    margin-left: 0 !important;
    margin-top: 0.75rem;
  }
}

@media screen and (max-width: 623px) {
  .create-booking-btn {
    padding: 5px;
    margin-bottom: 0.75rem;
  }
}

@media screen and (max-width: 1328px) {
  .dashboard-filter-components {
    margin-bottom: 0.8rem;
  }
  .dashboard-filter-btn .mx-2 {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 928px) {
  .dashboard-filter-btn {
    width: 100%;
  }
}

@media screen and (max-width: 697px) {
  .mobile-no-margin {
    margin-left: 0px !important;
  }

  .dashboard-arrow {
    display: none;
  }
}
