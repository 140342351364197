// 
// Custom-radio.scss
//


// checkbox & radio color
@each $color,
$value in $theme-colors {
    .form-check {
        &.form-check-#{$color} {
            .form-check-input:checked {
                background-color: $value !important;
                border-color: $value !important;
            }
        }
    }
}  

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-6 -6 12 12'%3e%3ccircle r='3' fill='%238b96a0'/%3e%3c/svg%3e");
}

.form-switch .form-check-input {
    background-color: #ffffff !important;
    border-color: #ced4da;
    /*background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -5 10 10'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;*/
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-6 -6 12 12'%3e%3ccircle r='3' fill='%238b96a0'/%3e%3c/svg%3e");
    height: 20px;
    width: 34px;
    margin-right: 10px;
    margin-top: 0;
}

.form-switch .form-check-input:checked {
    background-color: #1dbd9d !important;
    border-color: #1dbd9d;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-6 -6 12 12'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}


