#establishment-list-main-modal .tab-content {
    height: 95%;
    padding: 20px 0;
}
#establishment-list-main-modal  .tab-content form {
    width: 100%; 
    overflow: hidden;
}

#establishment-list-main-modal .tab-pane {
    height: 100%;
}
#establishment-list-main-modal .side-menu {
    border-right: 1px solid lightgray;
    max-height: 100%;
    overflow-Y: auto;
}

#establishment-list-main-modal .flex-column.nav {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}

#establishment-list-main-modal .main-content {
    height: 100%;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.modal-dialog.modal-80w {
    width: 80% !important;
    max-width: none!important;
}

.btn i {
    margin-right: 5px;
}

.callout {
    border-radius: 3px;
    margin: 0 0 20px 0;
    padding: 15px 30px 15px 15px;
    border: 1px solid #eee;
    border-left: 5px solid #eee;
}

.callout.callout-info {
    background-color: #f7f6f6 !important;
    border-color: #e5e4e4;
    color: #666666 !important;
}

.special {
    border-radius: 4px;
    padding: 15px;
    -webkit-box-shadow: 0 0px 8px rgb(0 0 0 / 25%);
    box-shadow: 0 0px 8px rgb(0 0 0 / 25%);
    margin-top: 20px;
    margin-bottom: 20px;
}

.over-model-background {
    background: black;
}

.over-model-dialog {
    -webkit-box-shadow: 0 0px 8px rgb(0 0 0 / 25%);
    box-shadow: 0 0px 8px rgb(0 0 0 / 25%);
}


.side-menu-dropdown .dropdown-item.nav-link,
.side-menu-dropdown2 .dropdown-item.nav-link 
{
    padding-left: 30px;
}

.side-menu i {
    margin-right: 5px;
}

.side-menu .dropdown-menu {
    position: static !important;
    transform: translate(0) !important;
    border: none !important;
}

.edit-review-modal-dialog
{
    max-height: 100vh !important;
    height: 90vh;
    overflow: hidden;
    -webkit-box-shadow: 0 0px 8px rgb(0 0 0 / 25%);
    box-shadow: 0 0px 8px rgb(0 0 0 / 25%);
}

.modal-scroll {
    max-height: 100vh !important;
    height: 90vh;
    -webkit-box-shadow: 0 0px 8px rgb(0 0 0 / 25%);
    box-shadow: 0 0px 8px rgb(0 0 0 / 25%);
}

.modal-scroll .modal-content,
.modal-scroll .modal-body
{
    height: 100%;
}

.modal-scroll .modal-body
{
    overflow-y: scroll;
}

/*==============================
    == Photos Page ==
==============================*/
.photo
{
    border: 1px solid lightgray;
}

.photo .inputs {
    padding: 15px;
}
.img-container {
    position: relative;
    
}

.img-container .img-icons 
{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.img-container .img-icons .ribbon{
    float: left;
    padding: 5px 10px;
}

.img-container .img-icons .icons{
    float: right;
}

.img-container .img-icons button {
    background: #fff !important;
    border: none;
    outline: none;
    text-align: 'center';
    border-radius: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.img-container .img-icons button i {
    margin: 0;
}

.img-container .img-icons button:first-child {
    margin-right: 10px;
}

.react-draft-wysiwyg-wrapper {
    border: 1px solid lightgray;
}

.demo-editor.rdw-editor-main {
    min-height: 200px;
    padding: 10px;
}

.modal-90w {
    width: 55%;
    max-width: none !important;
}

#rates-table td:hover {
    background-color: rgba(0,0,0, 0.05);
    cursor: pointer;
}